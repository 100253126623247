<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section-first-page-hero">
      <div class="new-features">
        <div
          class="container m-container-only-s"
          v-html="$t('pages.main_header')"
        ></div>
      </div>
    </section>
    <section class="section section-first-page-tiles">
      <div class="container m-container-only-s">
        <div class="tiles">
          <div
            class="tile two-cols-left tile-seats bottom"
            :class="{ 'disabled-tile': !isUpgrade || !isService }"
            @click.prevent="$router.push({ name: 'UpgradeSeat' })"
          >
            <div class="tile-container">
              <span class="new">{{ $t("pages.badge_new") }}</span
              ><br />
              <p v-html="$t('pages.main_upgrade')"></p>
              <router-link
                v-if="isUpgrade && isService"
                :to="{ name: 'UpgradeSeat' }"
                class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
              <span v-else class="disabled-btn">{{
                $t("pages.main_button_disabled")
              }}</span>
            </div>
          </div>

          <div
            class="tile tile-magazine top"
            @click.prevent="pushRoute('/journal/index')"
          >
            <div class="tile-container">
              <span class="new">{{ $t("pages.badge_new") }}</span>
              <br />
              <p v-html="$t('pages.main_journal')"></p>
              <a href="/journal/index" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></a
              >
            </div>
          </div>

          <div
            class="tile tile-about top"
            @click.prevent="$router.push({ name: 'Airline' })"
          >
            <div class="tile-container">
              <p v-html="$t('pages.main_info')"></p>
              <router-link :to="{ name: 'Airline' }" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
            </div>
          </div>

          <div
            class="tile two-cols-right tile-entertaiments top"
            @click.prevent="$router.push({ name: 'Main' })"
          >
            <div class="tile-container">
              <p v-html="$t('pages.main_ife')"></p>
              <router-link :to="{ name: 'Main' }" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
            </div>
          </div>

          <div
            class="tile two-cols-left tile-team bottom"
            :class="{
              'disabled-tile':
                !flight.crewEmployers ||
                (flight.crewEmployers && !flight.crewEmployers.length)
            }"
            @click.prevent="
              !flight.crewEmployers ||
              (flight.crewEmployers && !flight.crewEmployers.length)
                ? false
                : $router.push({ name: 'Crew' })
            "
          >
            <div class="tile-container">
              <span class="new">{{ $t("pages.badge_new") }}</span
              ><br />
              <p v-html="$t('pages.main_crew')"></p>
              <router-link
                v-if="flight.crewEmployers && flight.crewEmployers.length"
                :to="{ name: 'Crew' }"
                class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >

              <span v-else class="disabled-btn">{{
                $t("pages.main_button_disabled")
              }}</span>
            </div>
          </div>

          <div
            class="tile tile-press bottom"
            @click.prevent="$router.push({ name: 'Press' })"
          >
            <div class="tile-container">
              <span class="new">{{ $t("pages.badge_new") }}</span
              ><br />
              <p v-html="$t('pages.main_press')"></p>
              <router-link :to="{ name: 'Press' }" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
            </div>
          </div>

          <div
            class="tile tile-shop middle"
            @click.prevent="$router.push({ name: 'Shop' })"
          >
            <div class="tile-container">
              <span class="soon"> {{ $t("pages.badge_soon") }}</span
              ><br />
              <p v-html="$t('pages.main_shop')"></p>
              <router-link :to="{ name: 'Shop' }" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
            </div>
          </div>

          <div
            :class="{
              'disabled-tile': !isService
            }"
            class="tile tile-stuard middle"
            @click.prevent="$router.push({ name: 'Services' })"
          >
            <div class="tile-container">
              <span class="new">{{ $t("pages.badge_new") }}</span
              ><br />
              <p v-html="$t('pages.main_board')"></p>
              <router-link
                v-if="isService"
                :to="{ name: 'Services' }"
                class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
              <span v-else class="disabled-btn">
                {{ $t("pages.main_button_disabled") }}
              </span>
            </div>
          </div>

          <div
            :class="{
              'disabled-tile': !isFoodMenu
            }"
            class="tile two-cols-right tile-menu bottom"
            @click.prevent="$router.push({ name: 'Menu' })"
          >
            <div class="tile-container">
              <span class="new">{{ $t("pages.badge_new") }}</span
              ><br />
              <p v-html="$t('pages.main_menu')"></p>
              <router-link v-if="isFoodMenu" :to="{ name: 'Menu' }" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
              <span v-else class="disabled-btn">
                {{ $t("pages.main_button_disabled") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import cover from "@/mixins/cover";

import isFlight from "@/mixins/isFlight";
export default {
  name: "IndexMain",
  title() {
    return this.$t("pages.main");
  },
  mixins: [cover, isFlight],
  data: () => {
    return {
      video: null
    };
  },
  computed: {
    ...mapState("home", {
      indexData: state => state.index,
      indexLoading: state => state.indexLoading
    }),
    ...mapState("flight", {
      flight: state => state.flight
    }),
    ...mapState("settings", {
      enabledDrm: state => state.enabledDrm
    }),
    ...mapState({
      locale: state => state.locale
    }),
    ...mapState("section", {
      indexSectionData: state => state.index,
      indexSectionLoading: state => state.indexLoading
    }),
    backgroundHeaderImage() {
      return {
        backgroundImage: `url(${require("@/assets/" +
          this.headerItems[this.step].image)})`
      };
    }
  },
  watch: {
    indexLoading: {
      handler() {
        if (this.indexLoading === "empty") {
          this.actionIndex();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("home", {
      actionIndex: "index"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    pushRoute(href) {
      window.location.href = href;
    }
  }
};
</script>

<style scoped></style>
